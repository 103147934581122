import React from 'react';
import {TextField} from "@mui/material";

interface TitleNTaglineProps {
  title: string,
  setTitle: (val: string) => void,
  tagline: string,
  setTagline: (val: string) => void,
}

const TitleNTagline = (props: TitleNTaglineProps) => {
  const { title, setTitle, tagline, setTagline } = props;

  return (
    <div>
      <TextField
        margin={"dense"}
        label="Title"
        variant="filled"
        required
        fullWidth
        helperText={"up to 3 lines on the preview"}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        margin={"dense"}
        label="Tagline"
        variant="filled"
        fullWidth
        value={tagline}
        helperText={"up to 4 lines on the preview"}
        onChange={(e) => setTagline(e.target.value)}
      />
    </div>
  )
};

export default TitleNTagline;
