import {ContentColor} from "../../styles/colorCode";
import {iconAssets} from "../../assets/Icon";
import React from "react";

interface AddressProps {
  showAddress: boolean,
  showHours: boolean,
  showContact: boolean,
  address: string,
  color: ContentColor,
}

export const Address = (props: AddressProps) => {
  const {showAddress, showHours, showContact, address, color} = props;
  const iconSrc = iconAssets['Location'] ? iconAssets['Location'][color as ContentColor] : null;
  return showAddress
    ? (
      <div className={"address"}>
        <div className={"optional-icon"}>
          {/*<PlaceIcon sx={iconStyle}/>*/}
          <img src={iconSrc} alt={"Address"}/>
        </div>
        <div className={"wrapper-text"}>
          <div className={"text-overflow optional-limit-3"}>
            {address}
          </div>
          { showHours || showContact ?
            <div className={"dashed_bottom"}>
              ...................................................................................................
            </div>
            :
            null
          }
        </div>
      </div>
    )
    : null;
};
