import React from 'react';
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, TextField} from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

interface OptionalInfoProps {
  showAddress: boolean,
  setShowAddress: (val: boolean) => void,
  showHours: boolean,
  setShowHours: (val: boolean) => void,
  showContact: boolean,
  setShowContact: (val: boolean) => void,
  address: string,
  setAddress: (val: string) => void,
  hours: string,
  setHours: (val: string) => void,
  contactIcon: string,
  setContactIcon: (val: string) => void,
  contactInfo: string,
  setContactInfo: (val: string) => void,
}

const OptionalInfo = (props: OptionalInfoProps) => {
  const {
    showAddress,
    setShowAddress,
    showHours,
    setShowHours,
    showContact,
    setShowContact,
    address,
    setAddress,
    hours,
    setHours,
    contactIcon,
    setContactIcon,
    contactInfo,
    setContactInfo
  } = props;

  const addressField = showAddress
    ?
    <TextField
      margin={"dense"}
      label="Address"
      variant="filled"
      fullWidth
      value={address}
      helperText={"up to 3 lines on the preview"}
      onChange={(e) => setAddress(e.target.value)}
    />
    : null;

  const hoursField = showHours
    ?
    <TextField
      margin={"dense"}
      label="Hours"
      variant="filled"
      fullWidth
      value={hours}
      helperText={"up to 3 lines on the preview"}
      onChange={(e) => setHours(e.target.value)}
    />
    : null;

  const contactsField = showContact
    ?
    <div className={"align-left"}>
      <RadioGroup
        row
        aria-labelledby="contact-info-radio-buttons-group-label"
        name="contact-info-radio-buttons-group"
        onChange={(e) => setContactIcon(e.target.value)}
        value={contactIcon}
      >
        <FormControlLabel value="website" control={<Radio/>} label="Website"/>
        <FormControlLabel value="instagram" control={<Radio/>} label="Instagram"/>
        <FormControlLabel value="phone" control={<Radio/>} label="Phone"/>
      </RadioGroup>
      <TextField label="Contact Info"
                 margin={'dense'}
                 variant="filled"
                 fullWidth
                 value={contactInfo}
                 helperText={"up to 1 line on the preview"}
                 onChange={(e) => setContactInfo(e.target.value)}/>
    </div>
    : null;

  return (
    <div className={"align-left"}>
      <FormLabel id="contact-info-form-label">Optional Info</FormLabel>
      <div>
        <FormControlLabel value="address" label="Address"
                          control={<Checkbox checked={showAddress}
                                             onChange={(e) => setShowAddress(e.target.checked)}/>}/>
        <FormControlLabel value="hours" label="Hours"
                          control={<Checkbox checked={showHours}
                                             onChange={(e) => setShowHours(e.target.checked)}/>}/>
        <FormControlLabel value="contact-info" label="Contact Info"
                          control={<Checkbox checked={showContact}
                                             onChange={(e) => setShowContact(e.target.checked)}/>}/>
      </div>
      {addressField}
      {hoursField}
      {contactsField}
    </div>
  );
};

export default OptionalInfo;
