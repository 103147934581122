import React, {ForwardedRef, forwardRef, useEffect, useState} from 'react';
import {createGlobalStyle} from 'styled-components';
import '../styles/preview.css';
import {illusAssets} from '../assets/illustration';
import {colorCodes, ContentColor} from '../styles/colorCode';
import sampleLogo from '../sample/sampleLogo.png';
import {Optional} from './adDetails/Optional';
import {TypeOption} from "./adDetails/ContentType";

interface PreviewProps {
  color: ContentColor,
  type: keyof typeof TypeOption,
  illus: keyof typeof illusAssets,
  title: string,
  tagline: string,
  address: string,
  showAddress: boolean,
  hours: string,
  showHours: boolean,
  contactInfo: string,
  showContact: boolean,
  contactIcon: string,
  userLogo: string | null,
  showUserLogo: boolean,
}

const Preview = forwardRef(function Preview(props: PreviewProps, printRef: ForwardedRef<HTMLDivElement>) {
  const {
    color,
    type,
    illus,
    title,
    tagline,
    address,
    showAddress,
    hours,
    showHours,
    contactInfo,
    showContact,
    contactIcon,
    userLogo,
    showUserLogo,
  } = props;
  const [userLogoSrc, setUserLogoSrc] = useState(sampleLogo);

  const illustrationSrc = illusAssets[illus] ? illusAssets[illus][color as ContentColor] : null;

  const GlobalStyles = createGlobalStyle`
    * {
      --background-color-1: ${colorCodes[color as ContentColor].BackgroundColor1};
      --background-color-2: ${colorCodes[color as ContentColor].BackgroundColor2};
      --header-text-color: ${colorCodes[color as ContentColor].HeaderColor};
      --title-text-color: ${colorCodes[color as ContentColor].BodyTitleColor};
      --tagline-text-color: ${colorCodes[color as ContentColor].BodyTaglineColor};
      --optional-text-color: ${colorCodes[color as ContentColor].FooterTextColor};

      --logo__left__visibility: ${showUserLogo ? 'visible' : 'hidden'};
    }
  `;

  useEffect(() => {
    if (!userLogo) {
      return
    }
    setUserLogoSrc(userLogo)
  }, [userLogo])


  const titleAndTagline = (
    <div className={"title-n-tagline large"}>
      <div className={"title text-overflow limit-3"}>{title}</div>
      <div className={"tagline text-overflow limit-4"}>{tagline}</div>
    </div>
  );

  const logoAndOptional = (
    <div className={"small"}>
      <div className={"logo"}>
        <img src={userLogoSrc} alt={"user logo"}/>
        {illustrationSrc ? <img src={illustrationSrc} alt={"illustration"}/> : null}
      </div>
      <Optional showAddress={showAddress} showHours={showHours} showContact={showContact} color={color}
                address={address} hours={hours} contactInfo={contactInfo} contactIcon={contactIcon}/>
    </div>
  );

  return (
    <div>
      <GlobalStyles/>
      <div className={"preview font-preset"} ref={printRef}>
        <div className={"header large"}>{type}</div>
        <span className={"body"}>
          {titleAndTagline}
          {logoAndOptional}
        </span>
      </div>
    </div>
  );
});

export default Preview;
